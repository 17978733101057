import axios from "axios";
import { getToken } from "./auth";

//LOCAL SERVERS
// export const baseURL = "http://localhost:8000/api";
//  export const baseURL = "http://localhost/bettercare_laravel/api";

//UAT SERVER
  //  export const baseURL = "https://backend-test.bettercare.tech/api";

// LIVE SERVER
// export const baseURL = "https://interface.bettercare.tech/api";

//CS SERVER
 export const baseURL = "https://apoio-backend.bettercare.tech/api";


const api = axios.create({
  baseURL,
});

api.interceptors.request.use(async (config) => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export { api };
